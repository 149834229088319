import React, { useState, useEffect, useRef } from 'react';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import GPX from 'ol/format/GPX';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

import 'ol/ol.css';

const style = {
    'Point': new Style({
      image: new CircleStyle({
        fill: new Fill({
          color: 'rgba(255,255,0,0.4)',
        }),
        radius: 5,
        stroke: new Stroke({
          color: '#ff0',
          width: 1,
        }),
      }),
    }),
    'LineString': new Style({
      stroke: new Stroke({
        color: '#f00',
        width: 3,
      }),
    }),
    'MultiLineString': new Style({
      stroke: new Stroke({
        color: '#FFA500',
        width: 3,
      }),
    }),
  };
  
  // const centerLonLat = [10.669477, 59.439064];
  const centerLonLat = [13.5218426026, 59.3653967511];
  const centerWebMercator = fromLonLat(centerLonLat); 

const OpenSeaMap = ({ gpxUrl }) => {
    const [map, setMap] = useState();
    const mapElement = useRef();
    const mapRef = useRef();
    mapRef.current = map;

    useEffect(() => {
        const initialMap = new Map({
          target: mapElement.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
                new VectorLayer({
                    source: new VectorSource({
                      url: gpxUrl,
                      format: new GPX(),
                    }),
                    style: function (feature) {
                      return style[feature.getGeometry().getType()];
                    },
                  }),
            ],
            view: new View({
                center: centerWebMercator,
                zoom: 14,
            }),
        });
        setMap(initialMap);
    }, [gpxUrl]);

    return (
      <div ref={mapElement} className="map-container" />
    );
}


export default OpenSeaMap;
