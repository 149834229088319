import PropTypes from 'prop-types';

const Header = ({ title }) => {

    const onClick = () => {
        console.log('click');
    }

  return (
    <header className='header'>
        <h1>{title}</h1>
        <button className="btn" onClick={onClick}>Lägg till</button>
    </header>
  )
}

Header.defaultProps = {
    title: 'Loggbok',
};

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;