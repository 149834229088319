import Header from './components/Header';
// import Parser from './components/Parser';
import OpenSeaMap from './components/OpenSeaMap';

function App() {
  return (
    <div className="container">
      <Header />
      <OpenSeaMap gpxUrl="/gpx-data/2016-06-25.gpx" />
      {/* <Parser url="/gpx-data/2016-05-16.gpx" /> */}
    </div>
  );
}

export default App;
